import { stage } from '../../utils/stages';

const USER_REACTION_SERVICE_URLS: Record<string, string> = {
    dev: 'https://s7social-dev.qa-k8s-itg-1.web.s7.ru/user-reaction-service',
    qa: 'https://s7social-uat.ing.caas31u28.epaas.s7.aero/user-reaction-service',
    stage: 'https://social.api.s7.ru/user-reaction-service',
    production: 'https://social.api.s7.ru/user-reaction-service',
};

const MARKET_SERVICE_URLS: Record<string, string> = {
    dev: 'https://market-dev.api.s7.ru',
    qa: 'https://market-qa.api.s7.ru',
    stage: 'https://market.api.s7.ru',
    production: 'https://market.api.s7.ru',
};

const MARKET_URLS: Record<string, string> = {
    dev: 'https://market-dev.s7.ru',
    qa: 'https://market-qa.s7.ru',
    stage: 'https://shop.s7.ru',
    production: 'https://shop.s7.ru',
};

const MARKET_IMG_URLS: Record<string, string> = {
    dev: 'https://market-dev.s7.ru',
    qa: 'https://qa-market.s7cdn.online',
    stage: 'https://shop.s7cdn.online',
    production: 'https://shop.s7cdn.online',
};

const YA_IDS: Record<string, string> = {
    dev: '87713619',
    qa: '87713619',
    stage: '87713619',
    production: '4173733',
};

const GA_IDS: Record<string, string> = {
    dev: 'GTM-KRJNC2',
    qa: 'GTM-KRJNC2',
    stage: 'GTM-KRJNC2',
    production: 'GTM-MWTWZW',
};

const MEDIA_API_URLS: Record<string, string> = {
    dev: 'https://qa-s7media.s7.ru/S7WMediaService/api/v2/footer',
    qa: 'https://qa-s7media.s7.ru/S7WMediaService/api/v2/footer',
    stage: 'https://stage-s7media.s7.ru/S7WMediaService/api/v2/footer',
    production: 'https://media.s7.ru/S7WMediaService/api/v2/footer',
};

const COMPASS_URLS: Record<string, string> = {
    dev: 'https://dev-www.s7.ru/compass',
    qa: 'https://qa-www.s7.ru/compass',
    stage: 'https://stage-www.s7.ru/compass',
    production: 'https://www.s7.ru/compass',
};

const PORTAL_URLS: Record<string, string> = {
    dev: 'https://dev-www.s7.ru',
    qa: 'https://qa-www.s7.ru',
    stage: 'https://stage-www.s7.ru',
    production: 'https://s7.ru',
};

export const USER_REACTION_SERVICE_URL = USER_REACTION_SERVICE_URLS[stage];
export const MARKET_SERVICE_URL = MARKET_SERVICE_URLS[stage];
export const MARKET_URL = MARKET_URLS[stage];
export const MARKET_IMG_URL = MARKET_IMG_URLS[stage];

export const YA_ID = YA_IDS[stage];
export const GA_ID = GA_IDS[stage];
export const MEDIA_API_URL = MEDIA_API_URLS[stage];

export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const ONE_SIGNAL_ID = process.env.NEXT_PUBLIC_ONE_SIGNAL_ID;

export const COMPASS_URL = COMPASS_URLS[stage];
export const PORTAL_URL = PORTAL_URLS[stage];
